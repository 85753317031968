// export const SERVER_URL = "https://skunk-capable-penguin.ngrok-free.app";
// export const SERVER_URL = "https://skunk-capable-penguin.ngrok-free.app";

// export const SERVER_URL = "https://skunk-capable-penguin.ngrok-free.app";
// export const SERVER_URL = process?.env?.REACT_APP_SERVER_URL;
export const SERVER_URL = process.env.REACT_APP_BASE_SERVER_URL;
// export const SERVER_URL = "http://localhost:5000/api";
console.log(SERVER_URL);

// export const SERVER_BASE_URL = "http://localhost:5000";
// ngrok http --domain=closing-vocal-cougar.ngrok-free.app 3000
export const FB_APP_ID = "1085971379121509";
// 8904372449576967
// export
export const FB_CONFIG_ID = "309077698723590";
