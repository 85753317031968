import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = window?.localStorage?.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [timeout, setTimeOut] = useState(0);
  // const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      // window.location.href = "/app/auth/login";
      // navigate("/auth/login");
    } else {
      console.log(typeof user);
      setTimeOut(10000);
      window?.localStorage?.setItem("user", JSON?.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      console.log("Going to refetch user info and refresh token");
    }, timeout);
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [timeout]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
