import { Box, Button, Typography, IconButton } from "@mui/material";
import { useEffect, useRef, useState, useContext } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import { TemplateContext } from "../../Context/TemplateContext";
import Loading from "../Loading/Loading";
import * as XLSX from "xlsx";

export default function UploadContacts(props) {
  const {
    templates,
    setAudienceSelected,
    csvHeaders,
    setCsvHeaders,
    templateData,
    setBroadcastData,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [csvHeaders, setCsvHeaders] = useState([]);
  const [parsedData, setParsedData] = useState();
  const fileInputRef = useRef(null);
  const [disableUploadButton, setDisableUploadButton] = useState(true);
  const { selectedTemplate, setselectedtemplate } = useContext(TemplateContext);
  const handleFileInputChange = async (event) => {
    let broadCastData = {
      broadCastList: [],
    };
    const { components, ...otherTemplateinfo } = templateData;

    const file = event.target.files[0];
    if (!file) return;

    if (file.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const normalizedText = text.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
        const lines = normalizedText
          .split("\n")
          .filter((line) => line.trim() !== "");
        const headers = lines[0].split(",");
        const data = lines.slice(1).map((line) => {
          const values = line.split(",");
          let jsonObject = {};

          // Clone otherTemplateinfo and components for each line
          let localTemplateInfo = { ...otherTemplateinfo };
          let localComponents = components.map((component) => {
            // Deep clone each component, especially the body and substitutions array
            let clonedComponent = { ...component };
            if (clonedComponent.substitutions) {
              clonedComponent.substitutions = [
                ...clonedComponent.substitutions,
              ];
            }
            return clonedComponent;
          });

          headers.forEach((itemheader, index) => {
            let [header, body, footer, buttons] = localComponents;

            if (itemheader === "recipient_phone_number") {
              localTemplateInfo.to = values[index];
            } else if (Object.values(body?.mappings).includes(itemheader)) {
              const key = Object.entries(body?.mappings).find(
                ([_, value]) => value === itemheader
              )?.[0];
              body.substitutions[key - 1] = values[index];
            }
          });

          // Update the components in localTemplateInfo
          localTemplateInfo.components = localComponents;

          // Log the localTemplateInfo
          // console.log(localTemplateInfo);
          broadCastData.broadCastList?.push(localTemplateInfo);

          return jsonObject;
        });
        setBroadcastData((previosData) => ({
          ...previosData,
          broadCastList: broadCastData?.broadCastList,
        }));
        setParsedData(data);
        setSelectedFile(file);
      };

      reader.readAsText(file);
    } else if (file.type.includes("sheet") || file.type.includes("excel")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryString = e.target.result;
        const workbook = XLSX.read(binaryString, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const headers = data[0];
        const rows = data.slice(1);
        const jsonData = rows.map((row) => {
          let jsonObject = {};
          headers.forEach((header, index) => {
            jsonObject[header] = row[index];
          });
          return jsonObject;
        });

        setParsedData(jsonData);
        setSelectedFile(file);
      };
      reader.readAsBinaryString(file);
    } else {
      alert("Please upload a valid CSV or Excel file.");
    }
  };

  const generateBroadcastData = async () => {
    if (!templateData || !parsedData) {
      console.error("Template or data not found");
      return;
    }

    // Initialize an empty array to store the broadcast data
    const broadcastData = [];

    // Loop through each entry in parsedData
    for (const data of parsedData) {
      // Create a copy of templateData to modify for each record
      const modifiedTemplate = JSON.parse(JSON.stringify(templateData)); // Deep clone templateData

      // Replace placeholders in components
      modifiedTemplate.components = modifiedTemplate.components.map(
        (component) => {
          if (component.type === "BODY") {
            // Create a substitutions array based on the mappings
            const substitutions = Object.keys(component.mappings).map(
              (variable) => {
                return data[component.mappings[variable]] || `{{${variable}}}`;
              }
            );

            return {
              ...component,
              substitutions: substitutions, // Replace the substitutions array
            };
          }
          return component;
        }
      );

      // Add modified template to broadcastData
      broadcastData.push({
        ...modifiedTemplate,
        substitutions:
          modifiedTemplate.components.find((comp) => comp.type === "BODY")
            ?.substitutions || [],
      });
    }

    console.log(broadcastData);
    setBroadcastData(broadcastData); // Uncomment if you want to update state or handle further
  };

  const handleCloseIconClick = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // useEffect(() => {
  //   if (selectedFile) {
  //     generateBroadcastData();
  //   }
  // }, [selectedFile]);
  const handleFileInputClick = (event) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const generateCsvHeaders = () => {
    const selectedTemplateData = templates.find(
      (template) => template.id === selectedTemplate
    );

    if (!selectedTemplateData) {
      console.error("Template not found");
      return;
    }

    let headers = ["recipient_phone_number"];

    selectedTemplateData.components.forEach((component) => {
      if (component.mappings) {
        const values = Object.values(component.mappings);
        headers = headers.concat(values);
      }
    });

    setCsvHeaders(headers);
    setAudienceSelected(true);
  };

  const downloadFile = () => {
    setLoading(true);
    try {
      if (csvHeaders.length === 0) {
        console.error("No headers found");
        setLoading(false);
        return;
      }

      let csvContent = csvHeaders.join(",") + "\n";
      for (let i = 0; i < 10; i++) {
        csvContent += Array(csvHeaders.length).fill("").join(",") + "\n";
      }

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute("download", "template_mappings.csv");
      document.body.appendChild(link);
      link.click();
      setDisableUploadButton(false);
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error while downloading the file:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    generateCsvHeaders();
  }, [selectedTemplate, templates]);

  const steps = [
    {
      stepValue: 0,
      label: "Download .csv file",
      description:
        "Download this file and fill your contacts and message variable first",
      icon: <FileDownloadIcon size="small" />,
      function: downloadFile,
    },
    {
      stepValue: 1,
      label: "Upload .csv or .xlsx file",
      description: "Upload the .CSV or .XLSX file with the filled data here",
      function: handleFileInputClick,
      icon: <FileUploadIcon size="small" />,
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#FFF",
        mt: 2,
        pt: 1,
        pb: 1,
        borderRadius: "8px",
      }}
    >
      <Loading loading={loading} />
      {steps.map((step, index) => (
        <Box key={index}>
          <Box
            sx={{
              m: 1,
              ml: 2,
              p: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "auto",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor:
                    step?.stepValue <= activeStep ? "#7F2DF1" : "#848396",
                  p: 1,
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 700, fontSize: "14px", color: "#FFF" }}
                >
                  {step.stepValue + 1}
                </Typography>
              </Box>

              <Typography
                sx={{
                  ml: 2,
                  fontSize: "12px",
                  width: "80%",
                  textAlign: "left",
                  color: step?.stepValue <= activeStep ? "#000" : "#848396",
                }}
              >
                {step.description}
              </Typography>
            </Box>
            <input
              id="file-input"
              type="file"
              style={{ display: "none" }}
              accept=".csv, .xlsx"
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
            {step.stepValue === 0 ? (
              <Button
                startIcon={step.icon}
                disabled={step?.stepValue > activeStep}
                sx={{
                  minWidth: "150px",
                  color: "#FFF",
                  textTransform: "none",
                  fontSize: "10px",
                  fontWeight: 700,
                  borderRadius: "8px",
                  backgroundColor:
                    step?.stepValue <= activeStep ? "#7F2DF1" : "#848396",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#FFF",
                    background:
                      step?.stepValue <= activeStep ? "#7F2DF1" : "#848396",
                    boxShadow: "none",
                  },
                }}
                onClick={downloadFile}
              >
                {step.label}
              </Button>
            ) : selectedFile != null ? (
              <Box
                sx={{
                  mt: 2,
                  pl: 1,
                  border: "1px solid #A3AED0",
                  borderRadius: "8px",
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-between",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#4D4D4D",
                      fontSize: "16px",
                      fontWeight: 400,
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {selectedFile.name}
                  </Typography>

                  <IconButton onClick={handleCloseIconClick}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Button
                startIcon={step.icon}
                // disabled={step?.stepValue > activeStep}
                disabled={disableUploadButton}
                sx={{
                  minWidth: "150px",
                  color: "#FFF",
                  textTransform: "none",
                  fontSize: "10px",
                  fontWeight: 700,
                  borderRadius: "8px",
                  backgroundColor: disableUploadButton ? "#848396" : "#7F2DF1",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#FFF",
                    backgroundColor: disableUploadButton
                      ? "#848396"
                      : "#7F2DF1",
                    boxShadow: "none",
                  },
                }}
                onClick={handleFileInputClick}
              >
                {step.label}
              </Button>
            )}
          </Box>
          {step.stepValue === 0 && (
            <Box
              sx={{
                borderRight: "1px dashed #000",
                height: "30px",
                width: "50px",
                ml: -0.6,
              }}
            ></Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
