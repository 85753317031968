import { Box } from "@mui/material";
import { useState } from "react";
import WelcomeScreen from "./WelcomeScreen";
import HomePage from "./HomePage";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";

export default function DashBoard() {
  const [searchParams, setSearchParams] = useSearchParams();
  let { user } = useContext(UserContext);

  const [showDashboard, setShowDashboard] = useState(
    user?.buisnessId?.length > 0 ? true : false
  );

  return <>{showDashboard ? <HomePage /> : <WelcomeScreen />}</>;
}
