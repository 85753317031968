import React, { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { FB_APP_ID, FB_CONFIG_ID, SERVER_BASE_URL } from "../../constants";
import WhatsAppIcon from "../../Assets/Group-min.png";
import { UserContext } from "../../Context/UserContext";
import { SERVER_URL } from "../../constants";

// Facebook SDK loading and initialization
const loadFacebookSDK = () => {
  if (!document.getElementById("facebook-jssdk")) {
    const js = document.createElement("script");
    js.id = "facebook-jssdk";
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    document.body.appendChild(js);
  }

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: FB_APP_ID, // Replace with your Facebook App ID
      cookie: true, // enable cookies
      xfbml: true, // parse social plugins on this page
      version: "v19.0", // Graph API version
    });
    window.FB.AppEvents.logPageView();
  };
};

async function launchWhatsAppSignup(setCode, setAccessToken) {
  window.FB.login(
    function (response) {
      if (response?.authResponse?.code) {
        setCode(response?.authResponse?.code);
      }
      if (response?.authResponse?.accessToken) {
        setAccessToken(response?.authResponse?.accessToken);
      }
    },
    {
      config_id: FB_CONFIG_ID, // configuration ID goes here
      response_type: "code",
      override_default_response_type: true,
      extras: { sessionInfoVersion: 2 },
    }
  );
}

const sessionInfoListener = (event, setSignupData) => {
  if (event.origin !== "https://www.facebook.com") return;

  try {
    const data = JSON.parse(event.data);
    if (data.type === "WA_EMBEDDED_SIGNUP" && data.event === "FINISH") {
      const { phone_number_id, waba_id } = data.data;
      setSignupData({
        phoneNumberId: phone_number_id,
        whatsappBusinessAccountId: waba_id,
      });
    }
  } catch (error) {
    console.error("Error parsing session info response:", error);
  }
};

const FacebookLoginComponent = ({ type }) => {
  const [code, setCode] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [signupData, setSignupData] = useState({});
  const { user } = useContext(UserContext);

  const handleSignupComplete = async () => {
    try {
      const response = await axios.post(`${SERVER_URL}/whatsapp/requesttoken`, {
        code,
        user_id: user?.user_id,
        ...signupData,
      });
      console.log("Signup complete response:", response.data);
    } catch (error) {
      console.error("Error completing signup:", error);
    }
  };

  useEffect(() => {
    // Load Facebook SDK when the component mounts
    loadFacebookSDK();

    const listener = (event) => {
      sessionInfoListener(event, setSignupData);
    };

    // Add event listener for messages from Facebook
    window.addEventListener("message", listener);

    return () => {
      // Cleanup event listener on unmount
      window.removeEventListener("message", listener);
    };
  }, []);

  useEffect(() => {
    if (code) {
      handleSignupComplete();
    }
  }, [code]);

  return (
    <>
      {type === "onboard" ? (
        <Button
          variant="contained"
          onClick={() => launchWhatsAppSignup(setCode, setAccessToken)}
          sx={{
            color: "#FFF",
            pl: 5,
            pr: 5,
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: "10px",
            backgroundColor: "#7F2DF1",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#7F2DF1",
              color: "#FFF",
              boxShadow: "none",
            },
          }}
        >
          Launch WhatsApp Onboarding
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={() => launchWhatsAppSignup(setCode, setAccessToken)}
          sx={{
            color: "#7F2DF1",
            pl: 4,
            pr: 4,
            mr: 2,
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: "10px",
            backgroundColor: "#FFF",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              color: "#5e16c4",
              boxShadow: "none",
            },
          }}
          startIcon={
            <img
              height="25px"
              width="25px"
              src={WhatsAppIcon}
              alt="WhatsApp Icon"
            />
          }
        >
          Apply for WhatsApp Business API (FREE)
        </Button>
      )}
    </>
  );
};

export default FacebookLoginComponent;
