import { Grid, Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import io from "socket.io-client";
import ContactHeader from "../../components/Conversation/ContactHeader";
import ConversationSearch from "../../components/Conversation/ConversationSearch";
import ContactsBar from "../../components/Conversation/Contactsbar";
import ConversationHeader from "../../components/Conversation/ConversationHeader";
import ConversationBody from "../../components/Conversation/ConversationBody";
import ConversationFooter from "../../components/Conversation/ConversationFooter";
import { useContext } from "react";
import ChatContext from "../../Context/Chatcontext";

import { SERVER_URL } from "../../constants";
import { UserContext } from "../../Context/UserContext";

export default function Conversations() {
  const [showSearch, setShowSearch] = useState(false);
  const [chats, setChats] = useState({});
  const [socket, setSocket] = useState(null);
  const [page, setPage] = useState(1); // For pagination
  const {
    conversation,
    setConversations,
    messages,
    setMessages,
    selectedConvo,
    setSelectedConvo,
  } = useContext(ChatContext);
  // console.log(selectedConvo);
  const { user, setUser } = useContext(UserContext);

  const selectedConvoRef = useRef(selectedConvo); // Create a ref for selectedConvo
  const messagesRef = useRef(messages);

  useEffect(() => {
    selectedConvoRef.current = selectedConvo; // Keep the ref updated with the current value
  }, [selectedConvo]);
  useEffect(() => {
    messagesRef.current = messages; // Keep the ref updated with the current value
  }, [messages]);

  const getMessages = async (page) => {
    try {
      const res = await axios.post(
        `${SERVER_URL}/chats/getmessages/${selectedConvo?.conversationId}`,
        {
          page, // Pass page number for pagination
        }
      );
      if (res.status === 200) {
        setChats((prevChats) => ({
          ...prevChats,
          conversations: [
            ...(prevChats.conversations || []),
            ...res.data.conversations,
          ],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sendMessage = (message) => {
    console.log(message);
    if (message?.senderId === undefined || message?.conversationId) {
      let updatemessage = {
        ...message,
        conversationId: selectedConvo?.conversationId,
        senderId: selectedConvo?.conversationId?.split(":")[0],
      };
      // console.log(updatemessage);
      const updatedMessages = [updatemessage, ...messages];
      setMessages(updatedMessages);
      socket?.emit("sendmessage", updatemessage);
    } else {
      const updatedMessages = [message, ...messages];
      setMessages(updatedMessages);
      socket?.emit("sendmessage", message);
    }

    // console.log(updatedMessages);
  };

  const loadMoreMessages = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // useEffect(() => {
  //   getConversation();
  // }, []);

  useEffect(() => {
    if (page > 1) {
      getMessages(page);
    }
  }, [page]);

  useEffect(() => {
    const newSocket = io(`${SERVER_URL}`, {
      auth: {
        token: {
          id: user?.buisnessId[0]?.id,
        },
      },
    });
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to server");
    });

    newSocket.on("newmessage", (message) => {
      console.log("Current selectedConvo:", selectedConvoRef.current);

      if (window.location.pathname !== "/app/dashboard/chats") {
        alert(
          `message! From ${
            message?.conversationId?.split(":")[1]
          }  : message : ${message?.type === "text" ? message?.message : ""}`
        );
      }

      if (
        message?.conversationId === selectedConvoRef.current?.conversationId
      ) {
        const updatedMessages = [message, ...messagesRef.current];
        setMessages(updatedMessages);
      } else {
        console.log("Not the selected convo");
      }
    });

    // newSocket?.on("test", (data) => {
    //   console.log(data);
    //   alert(data?.data);
    // });

    newSocket.on("disconnect", () => {
      newSocket?.off("newmessage");
      console.log("Disconnected from server");
    });

    const handleBeforeUnload = (event) => {
      // Send a socket event to the backend
      newSocket.emit("user-disconnected", { id: "1" });

      // Optionally, you can show a confirmation dialog to the user
      // const message = 'Are you sure you want to leave?';
      // event.returnValue = message; // Standard for most browsers
      // return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      // newSocket.disconnect();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        backgroundColor: "#FFF",
      }}
    >
      <Grid
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        sx={{
          height: "100%",
          borderRadius: "12px 0px 0px 12px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "70px",
            backgroundColor: "#FFFFFF",
            border: "1px solid #F2F2F2",
            borderRadius: "12px 0px 0px 0px",
          }}
        >
          <ContactHeader
            showSearch={showSearch}
            setShowSearch={setShowSearch}
          />
        </Box>
        <ConversationSearch
          showSearch={showSearch}
          setShowSearch={setShowSearch}
        />
        <ContactsBar
          showSearch={showSearch}
          conversation={conversation}
          setConvo={(id) => setSelectedConvo(id)}
        />
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            borderRadius: "0px 12px 12px 0px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <ConversationHeader selectedConvo={selectedConvo} />
          <ConversationBody
            chats={chats}
            loadMoreMessages={loadMoreMessages}
            messages={messages}
          />
          <ConversationFooter
            sendMessage={sendMessage}
            socket={socket}
            selectedConvo={selectedConvoRef?.current}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
