import React from "react";
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "./Context/UserContext";

const ProtectedRoute = ({ element, isProtected }) => {
  const { user } = useContext(UserContext);

  if (isProtected) {
    // If the route is protected and no user is logged in, redirect to login
    if (!user) {
      return <Navigate to="/auth/login" />;
    }
    // If the user is logged in, allow access to the protected route
    return element;
  } else {
    // If the route is public (e.g., login) and the user is logged in, redirect to dashboard
    if (user) {
      return <Navigate to="/dashboard/home" />;
    }
    // If no user is logged in, allow access to the public route
    return element;
  }
};

export default ProtectedRoute;
