import { Box, Button } from "@mui/material";
import BroadcastStepper from "../../../components/BroadCasting/Stepper";
import { useState, useEffect } from "react";
import SelectAudience from "../../../components/BroadCasting/SelectAudience";
import SelectTemplate from "../../../components/BroadCasting/SelectTemplate";
import SendBroadcast from "../../../components/BroadCasting/SendBroadcast";
import axiosInstance from "../../../auth/axiosConfig";
import { TemplateContext } from "../../../Context/TemplateContext";
import { useContext } from "react";
import axios from "axios";

export default function CreateBroadcast() {
  const [steps, setSteps] = useState([
    "Select Template",
    "Select Audience",
    "Send Broadcast",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [templates, setTemplates] = useState([]);
  const [selctedTemplate, setSelectedTemplate] = useState();
  const [csvHeaders, setCsvHeaders] = useState([]);
  const { selectedTemplate, setselectedtemplate } = useContext(TemplateContext);
  const [broadcastData, setBroadcastData] = useState({});
  // console.log(broadcastData);
  const getAllTemplates = async () => {
    try {
      const res = await axios?.post(
        "https://e838-2401-4900-7b75-263f-b8e-d0a3-afca-cfe.ngrok-free.app/templates/gettemplates",
        {
          buisnessId: "275986335588625",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
      if (res?.status === 200) {
        const approvedTemplates = res?.data?.templates?.filter(
          (template) => template.status === "APPROVED"
        );
        setTemplates(approvedTemplates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SendBroadcastData = async () => {
    try {
      console.log(broadcastData);
      const res = await axios?.post(
        `https://e838-2401-4900-7b75-263f-b8e-d0a3-afca-cfe.ngrok-free.app/broadcast/send-broadcast`,
        broadcastData,
        {}
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);
  const getComponentByLabel = (label) => {
    switch (label) {
      case "Select Template":
        return (
          <SelectTemplate
            templates={templates}
            setSelectedTemplate={setSelectedTemplate}
          />
        );
      case "Select Audience":
        return (
          <SelectAudience
            templates={templates}
            templateData={selctedTemplate}
            csvHeaders={csvHeaders}
            setCsvHeaders={setCsvHeaders}
            setBroadcastData={setBroadcastData}
          />
        );
      case "Send Broadcast":
        return (
          <SendBroadcast
            templateData={selctedTemplate}
            broadcastData={broadcastData}
            setBroadcastData={setBroadcastData}
          />
        );
      default:
        return <></>;
    }
  };

  function moveToNextStep() {
    if (activeStep === 2) {
      return;
    }
    if (activeStep === 0 && selectedTemplate) {
      setActiveStep((currentStep) => currentStep + 1);
    }
    if (activeStep === 1) {
      setActiveStep((currentStep) => currentStep + 1);
    }
  }

  function moveToPrevStep() {
    if (activeStep != 0) {
      setActiveStep((currentStep) => currentStep - 1);
    }
  }
  return (
    <Box sx={{ width: "100%", height: "100%", mt: -2 }}>
      <BroadcastStepper
        steps={steps}
        activeStep={activeStep}
        completed={completed}
      />

      <Box
        sx={{
          width: "100%",
          mt: 2,
          height: "80%",
        }}
      >
        {getComponentByLabel(steps[activeStep])}
      </Box>
      <Box
        sx={{
          width: "100%",
          mt: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            pl: 5,
            pr: 5,
            color: "#7F2DF1",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: 700,
            borderRadius: "8px",
            border: "1px solid #7F2DF1",
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #7F2DF1", // Grayish background color
              color: "#7F2DF1",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            moveToPrevStep();
          }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          sx={{
            pl: 5,
            pr: 5,
            ml: 2,
            color: "#FFF",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: 700,
            borderRadius: "8px",
            backgroundColor: "#7F2DF1",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#7F2DF1", // Grayish background color
              color: "#FFF",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            if (steps[activeStep] === "Send Broadcast") {
              SendBroadcastData();
            } else {
              moveToNextStep();
            }
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
