import axios from "axios";
import { SERVER_URL } from "../constants";

let authToken;

export function setAuthToken(token) {
  authToken = token;
  axiosInstance.defaults.headers.common["Authorization"] = `${token}`;
}

export function getAuthToken() {
  return authToken;
}

const axiosInstance = axios.create({
  baseURL: `${SERVER_URL}`,
  timeout: 10000,
});

export default axiosInstance;
