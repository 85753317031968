import logo from "./logo.svg";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Layouts from "./layouts";
import { theme } from "./Theme/Theme";
import { ThemeProvider } from "@mui/material";
import SignUp from "./Screens/Auth/SignUp";
import Login from "./Screens/Auth/Login";
import ForgotPassword from "./Screens/Auth/ForgotPassword";
import VerifyOTP from "./Screens/Auth/VerifyOTP";
import AuthProvider from "react-auth-kit";

import TemplateProvider from "./Context/TemplateContext";
import { useContext } from "react";
import { UserContext, UserContextProvider } from "./Context/UserContext";
import {AuthRoutes} from "./Screens/Auth/AuthLayouts.jsx"
import ProtectedRoute from "./protectedRoute";
import { Navigate } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <TemplateProvider>
        <Router basename="/app">
            <Routes>
              {/* Redirect root path to login page */}
              <Route path="/" element={<Navigate to="/auth/login" />} />
              
              {/* Protected routes */}
              <Route
                path="/dashboard/*"
                element={
                  <ThemeProvider theme={theme}>
                    <ProtectedRoute element={<Layouts />} isProtected={true} />
                  </ThemeProvider>
                }
              />
              
              {/* Public routes */}
              <Route
                path="/auth/login"
                element={
                  <ProtectedRoute element={<Login />} isProtected={false} />
                }
              />
              <Route
                path="/auth/signup"
                element={
                  <ProtectedRoute element={<SignUp />} isProtected={false} />
                }
              />
              <Route
                path="/auth/forgotpassword"
                element={
                  <ProtectedRoute element={<ForgotPassword />} isProtected={false} />
                }
              />
              <Route
                path="/auth/verifyotp"
                element={
                  <ProtectedRoute element={<VerifyOTP />} isProtected={false} />
                }
              />
            </Routes>
          </Router>
        </TemplateProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
