import { TextField, Box, Button, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useRef } from "react";
export default function ConversationFooter({
  socket,
  sendMessage,
  selectedconvo,
}) {
  console.log(selectedconvo);
  const convoref = useRef(null);
  useEffect(() => {
    convoref.current = selectedconvo;
  }, [selectedconvo]);

  const [message, setmessage] = useState("");
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#EDEDED",
        borderRadius: "0pc 0px 12 px 0px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        pt: 2,
        pb: 2,
      }}
    >
      <Box sx={{ width: "95%" }}>
        <TextField
          onChange={(e) => setmessage(e?.target?.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  sx={{
                    color: "#FFF",
                    backgroundColor: "#7F2DF1",
                    fontWeight: 500,
                    fontSize: "12px",
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#7F2DF1",
                      color: "#FFF",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => {
                    sendMessage({
                      messageid: uuid(),
                      wamessageId: null,
                      senderId:
                        convoref?.current?.conversationId?.split(":")[0],
                      messageType: "text",
                      messageEvent: "outgoing",
                      conversationId: convoref?.current?.conversationId,
                      message: message,
                    });
                  }}
                >
                  Send
                </Button>
              </InputAdornment>
            ),
            style: {
              fontSize: "14px",
              height: "45px",
              borderRadius: "8px",
              backgroundColor: "#FFF",
            },
          }}
          size="small"
          fullWidth
        />
      </Box>
    </Box>
  );
}
