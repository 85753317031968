import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import { SERVER_URL } from "../constants";
const ChatContext = createContext();
const wabaphone = "15550717907";
export const ChatContextProvider = ({ children }) => {
  const [conversation, setConversations] = useState(null);
  const [messages, setMessages] = useState(null);
  const [selectedConvo, setSelectedConvo] = useState(null);
  //   const []

  useEffect(() => {
    const getConversation = async () => {
      try {
        const res = await axios.post(`${SERVER_URL}/chats/getConversations`, {
          wabaphone: wabaphone,
        });
        if (res.status === 200) {
          setConversations(res.data.conversations);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getConversation();
  }, []);

  useEffect(() => {
    const getMessages = async (page) => {
      try {
        const res = await axios.post(
          `${SERVER_URL}/chats/getmessages/${selectedConvo?.conversationId}`,
          {
            page, // Pass page number for pagination
          }
        );
        if (res.status === 200) {
          setMessages(res?.data?.conversations);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedConvo !== null) {
      getMessages();
    }
  }, [selectedConvo]);

  return (
    <ChatContext.Provider
      value={{
        conversation,
        setConversations,
        messages,
        setMessages,
        selectedConvo,
        setSelectedConvo,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContext;
