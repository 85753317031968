import { Box, CircularProgress, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import whatsappDoodle from "../../Assets/whatsappdoodle.jpeg";
import ChatBubble from "../Templates/TemplateCreation/ChatBubble";

export default function ConversationBody({
  chats,
  loadMoreMessages,
  messages,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const lastMessageRef = useRef(null);

  // Scroll to the bottom of the messages whenever the messages array changes
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column-reverse", // This keeps the latest message at the bottom
        background: "#FFFFFF",
        flexGrow: 1,
        backgroundImage: `url(${whatsappDoodle})`,
        backgroundRepeat: "repeat",
        backgroundSize: "400px",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {isLoading && (
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <CircularProgress size={24} />
        </Box>
      )}

      {messages?.map((item, index) => {
        if (
          item?.messageEvent === "broadcast" &&
          item?.messageType === "template"
        ) {
          return (
            <Box
              key={index}
              sx={{
                width: "350px",
                height: "350px",
                padding: "5%",
                position: "relative", // Adjusted to relative so it doesn't overlap
              }}
            >
              <ChatBubble templateData={JSON?.parse(item?.message)} />
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                maxWidth: "70%", // Adjust the width as needed
                width: "fit-content", // Allows the box to fit content width
                height: "auto",
                paddingX: "10px",
                paddingY: "5px", // Increased padding for a more spacious look
                borderRadius: "10px", // Rounded corners
                backgroundColor:
                  item?.messageEvent === "incoming" ? "#ffffff" : "#e1ffc7", // Different background color for incoming vs outgoing
                color: "#000000", // Text color
                margin: "10px", // Margin to space out messages
                position: "relative", // To position the triangle
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", // Optional shadow for better appearance
                alignSelf:
                  item?.messageEvent === "incoming" ? "flex-start" : "flex-end", // Align left or right based on type
                textAlign: "left", // Ensure text alignment is appropriate
              }}
              key={index}
              ref={index === messages.length - 1 ? lastMessageRef : null} // Add this line
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "1px",
                  margin: "4px",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "pre-wrap", // Allows for wrapping and preserves newlines
                  }}
                >
                  {item?.message}
                </Typography>
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
}
