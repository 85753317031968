import { Avatar, Box, Typography } from "@mui/material";
export default function ConversationHeader({ selectedConvo }) {
  return (
    <Box
      sx={{
        top: 0,
        height: "70px",
        // backgroundColor: "#FFFFFF",
        border: "1px solid #F2F2F2",
        borderRadius: "0px 12px 0px 0px",
        position: "sticky",
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
        paddingX: "10px",
      }}
    >
      <Avatar
        sx={{
          p: 1,
          height: "25px",
          width: "25px",
          mr: 1,
        }}
        src={selectedConvo?.icon}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "auto",
          width: "68%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: "#030229",
          }}
        >
          {selectedConvo?.conversationName}
        </Typography>
      </Box>
    </Box>
  );
}
