import { Box, Icon, IconButton, Typography } from "@mui/material";
import adobeIcon from "../../../Assets/adobeFIleIcon.svg";
import React, { useEffect, useState } from "react";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import CallIcon from "@mui/icons-material/Call";
import ReplyIcon from "@mui/icons-material/Reply";
import ListIcon from "@mui/icons-material/List";
import { SERVER_URL } from "../../../constants";

export default function ChatBubble(props) {
  const { templateData } = props;
  const { components } = templateData;
  const [templateBody, setTemplateBody] = useState(
    templateData?.templateBody || ""
  );

  const handleClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    setTemplateBody(
      replaceVariablesWithValues(
        templateData?.templateBody,
        templateData?.templateVariables
      )
    );
  }, [templateData?.templateVariables]);

  const replaceVariablesWithValues = (templateBody, templateVariables) => {
    let newTemplateBody = templateBody;
    templateVariables?.forEach((variable) => {
      if (variable?.value) {
        const variablePlaceholder = variable?.variable;
        let variableIndex = newTemplateBody?.indexOf(variablePlaceholder);
        while (variableIndex !== -1) {
          newTemplateBody =
            newTemplateBody?.slice(0, variableIndex) +
            variable.value +
            newTemplateBody.slice(variableIndex + variablePlaceholder.length);
          variableIndex = newTemplateBody.indexOf(
            variablePlaceholder,
            variableIndex + variable.value.length
          );
        }
      }
    });
    return newTemplateBody;
  };

  const substituteValues = (type, components) => {
    // Find the component of the specified type
    const component = components.find((comp) => comp.type === type);

    if (!component) {
      return `Component of type "${type}" not found.`;
    }

    let { text, substitutions } = component;

    // Ensure the substitutions array is not empty
    if (substitutions && substitutions.length > 0) {
      for (let index = 0; index < substitutions.length; index++) {
        const placeholder = `{{${index + 1}}}`;
        if (substitutions[index]) {
          text = text.split(placeholder).join(substitutions[index]);
        } else {
          // If any substitution is missing, return the original text
          return component.text;
        }
      }
    }

    return text;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFF",
        // minHeight: "80%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxWidth: "80%",
        position: "relative",
        p: 1,
        pt: 1,
        pb: 2,
        mb: 2,
        top: "20px",
        left: "10px",
        borderRadius: "8px",
      }}
    >
      <>
        {templateData?.components?.map((component) => {
          if (component?.type === "HEADER" && component.format === "image") {
            let file = component?.substitution[0];
            const imageUrl = templateData?.file
              ? `${SERVER_URL}/templates/img/${templateData.file}`
              : URL.createObjectURL(file);
            return (
              component?.substitution?.length && (
                <Box
                  sx={{
                    width: "100%",

                    // height: "150px",
                    // border: "1px solid black",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "8px" }}
                    src={imageUrl}
                    alt="-"
                  />
                </Box>
              )
            );
          }
          return null;
        })}
      </>

      <>
        {templateData?.components?.map((component) => {
          if (component?.type === "HEADER" && component.format === "video") {
            return (
              component?.substitution?.length && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      // height: "150px",
                      borderRadius: "8px",
                      zIndex: 1,
                    }}
                  >
                    <video
                      src={URL.createObjectURL(component?.substitution[0])}
                      width="100%"
                      // height="100%"
                      style={{ borderRadius: "8px" }}
                      autoPlay
                      muted
                    >
                      <track kind="captions" />
                    </video>
                  </Box>
                </>
              )
            );
          }
          return null;
        })}
      </>

      <>
        {templateData?.components?.map((component) => {
          if (component?.type === "HEADER" && component.format === "file") {
            return (
              component?.substitution?.length && (
                <Box
                  sx={{
                    backgroundImage: `url(${adobeIcon})`,
                    backgroundSize: "50px", // Set the size of the background image
                    backgroundPosition: "center", // Position the background image in the center
                    backgroundRepeat: "no-repeat", // Do not repeat the background image
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Background color with opacity
                    borderRadius: "8px",
                    height: "150px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      mb: 1,
                      width: "100%",
                      height: "18px",
                      fontSize: "12px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {component?.substitution[0].name}
                  </Typography>
                </Box>
              )
            );
          }
          return null;
        })}
      </>

      <>
        {templateData?.components?.map((component) => {
          if (component?.type === "HEADER" && component.format === "text") {
            return (
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: "start",
                  }}
                >
                  {substituteValues("HEADER", templateData.components)}
                </Typography>
              </Box>
            );
          }
          return null;
        })}
      </>
      <>
        {templateData?.components?.map((component) => {
          if (component?.type === "BODY") {
            return (
              <Box sx={{ mt: 1 }}>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    textAlign: "start",
                    width: "100%",
                    maxWidth: "95%",
                    overflow: "auto",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      substituteValues("BODY", templateData.components) || "",
                  }}
                ></div>
              </Box>
            );
          }
          return null;
        })}
      </>
      <>
        {templateData?.components?.map((component) => {
          if (component?.type === "FOOTER") {
            return (
              <Box sx={{ mt: 1 }} key={component?.type}>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: 500,
                    color: "#A3AED0",
                    textAlign: "start",
                    width: "100%",
                    maxWidth: "95%",
                    overflow: "auto",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                >
                  {component?.text}
                </div>
              </Box>
            );
          }
          return null;
        })}
      </>

      {console.log()}
      {templateData && templateData?.Button && templateData?.Button[0] && (
        <div
          style={{
            cursor: templateData?.Button[0].url ? "pointer" : "default",
          }}
          onClick={() => {
            if (templateData?.Button[0]?.url) {
              window.open(templateData.Button[0].url, "_blank");
            }
          }}
        >
          <Box
            sx={{
              marginTop: 2,
              borderTop: "0.2px solid #EAEAEA",
              borderBottom: "0.2px solid #EAEAEA",
              height: "35px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {templateData?.Button[0].type &&
            templateData?.Button[0].type === "URL" ? (
              <IconButton sx={{ color: "#32a4a8" }}>
                <LaunchOutlinedIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            ) : templateData?.Button[0].type === "PHONE_NUMBER" ? (
              <IconButton sx={{ color: "#32a4a8" }}>
                <CallIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            ) : (
              <IconButton sx={{ color: "#32a4a8" }}>
                <ReplyIcon sx={{ height: "20px", width: "20px", ml: -2 }} />
              </IconButton>
            )}

            <Typography
              sx={{ fontSize: "12px", fontWeight: 700, color: "#32a4a8" }}
            >
              {templateData?.Button[0].text}
            </Typography>
          </Box>
        </div>
      )}
      {templateData && templateData?.Button && templateData?.Button[1] && (
        <div
          style={{
            cursor: templateData?.Button[1].url ? "pointer" : "default",
          }}
          onClick={() => {
            if (templateData?.Button[1]?.url) {
              window.open(templateData.Button[1].url, "_blank");
            }
          }}
        >
          <Box
            sx={{
              borderBottom: "0.2px solid #EAEAEA",
              height: "35px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {templateData?.Button[1].type &&
            templateData?.Button[1].type === "URL" ? (
              <IconButton sx={{ color: "#32a4a8" }}>
                <LaunchOutlinedIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            ) : templateData?.Button[1].type === "PHONE_NUMBER" ? (
              <IconButton sx={{ color: "#32a4a8" }}>
                <CallIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            ) : (
              <IconButton sx={{ color: "#32a4a8" }}>
                <ReplyIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            )}
            <Typography
              sx={{ fontSize: "12px", fontWeight: 700, color: "#32a4a8" }}
            >
              {templateData?.Button[1].text}
            </Typography>
          </Box>
        </div>
      )}
      {templateData &&
        templateData?.Button &&
        templateData?.Button?.length > 2 && (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (templateData?.Button[1]?.url) {
                window.open(templateData.Button[2].url, "_blank");
              }
            }}
          >
            <Box
              sx={{
                borderBottom: "0.2px solid #EAEAEA",
                height: "35px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton disableRipple sx={{ color: "#32a4a8" }}>
                <ListIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>

              <Typography
                sx={{ fontSize: "12px", fontWeight: 700, color: "#32a4a8" }}
              >
                see all options
              </Typography>
            </Box>
          </div>
        )}
    </Box>
  );
}
